@import url(https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&family=Varela+Round&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200&display=swap);
@font-face {
  font-family: "Saudagar";
  src: 
   url(/static/media/Saudagar.966095a7.ttf) format("truetype");
  font-weight: 400;
  }

.slider-div{
    overflow-x: hidden;
}

.slider-div .swiper-slide {
    background-position: center;
    background-size: cover;
    color: #AFC9DC;
    -webkit-text-fill-color: transparent;
}

.slider-div .swiper-slide-active {
    font-size: 54px;
    color: #00618E;
    -webkit-text-decoration: underline 8px solid #111111;
            text-decoration: underline 8px solid #111111;
    -webkit-text-fill-color: #00618E;
}


.slider-div .slider {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 36%;
}


.profile-sect {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.profile-sec {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

.slider-image {
    width: 150px; /* Set a fixed width */
    height: 150px; /* Set a fixed height */
    object-fit: contain; /* Ensures the image is scaled to fit without stretching */
    display: block; /* Ensures it behaves like a block element */
    margin: auto; /* Centers the image horizontally */
  }
  


@media (min-width: 1065px) and (max-width: 1368px) {
    .swiper-slide-active {
        font-size: 40px;
    }

    .slider-div .slider {
        width: 38%;
    }

}

@media (min-width: 768px) and (max-width: 1064px) {
    .swiper-slide-active {
        font-size: 30px;
    }

    .slider-div .slider {
        width: 53%;
    }

    .slider {
        display: flex;
        justify-content: center;
        align-content: center;

    }
}

@media(min-width: 640px) and (max-width:768px) {
    .swiper-slide-active {
        -webkit-text-decoration: underline 5px solid #111111;
                text-decoration: underline 5px solid #111111;
    }

    .slider-div .slider {
        width: 60%;
    }
}

@media (min-width: 360px) and (max-width:640px) {
    .swiper-slide-active {
        -webkit-text-decoration: underline 2px solid #111111;
                text-decoration: underline 2px solid #111111;
    }

    .slider {
        width: 68%;
    }

    .slider-div .slider {
        width: 60%;
    }

    .slider-image {
        width: 70px; /* Set a fixed width */
        height: 90px; /* Set a fixed height */
      }


}

@media (min-width: 240px) and (max-width:359px) {
    .swiper-slide-active {
        -webkit-text-decoration: underline 2px solid #111111;
                text-decoration: underline 2px solid #111111;
    }

    .slider-div .slider {
        width: 78%;
    }

    .slider-image {
        width: 70px; /* Set a fixed width */
        height: 90px; /* Set a fixed height */
      }
}
.animated-divs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.animated-div {
  display: flex;
  justify-content: center;
  align-items: center;

}


.field1 {
  background-color: #111111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.field {
  background-color: #111111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100vh;
  margin-left: -2em;
}



.frames {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.frames img {
  width: 40em;
}

.frame {
  margin: 5%;
  margin-top: 10%;
}

.frameText {
  margin-top: 6%;
  color: #F2F4F6;
  text-decoration: underline;
  -webkit-text-decoration-color: #00618E;
          text-decoration-color: #00618E;
  font-size: 3.8em;
  font-family: "Saudagar";
  font-weight: 300;
}

.textdes {
  margin-top: 1%;
  color: #F2F4F6;
  margin-top: 2%;
  font-size: 18px;
  line-height: 1.4;
  width: 30em;
  margin-right: 13em;
}

.TEXT {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: -10em;
  margin-left: 3em;
}


.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: -19.8em;
  ;
  margin-top: -2em;
}

.btnOur {
  background-color: #00618E;
  color: #F2F4F6;
  border-radius: 10px;
  border: none;
  width: 150px;
  height: 35px;
  box-shadow: 0px 10px 70px #00618E;
  margin-top: 30%;
  margin-left: -1.7em;
}

.btnOur {
  cursor: pointer;
}

.btnUs {
  background-color: #111111;
  color: #F2F4F6;
  border-radius: 10px;
  border-color: #F2F4F6;
  width: 150px;
  height: 35px;
  margin-left: 7%;
  margin-top: 30%;
}

.btnUs:hover {
  cursor: pointer;
}


@media (min-width: 1065px) and (max-width: 1365px){
  .textdes{
    font-size: 16px;
  }
  .frames img {
    width: 30em;
}
}


@media (min-width:768px) and (max-width: 1064px) {
  .field {
    height: auto;
    margin-left: 0;
    flex-direction: column;
  }
  .TEXT {
    margin-left: 0em;
    font-size: 18px;
    margin-right: 0;
  }

  .textdes{
    margin-right: 0;
  }

  .frames img {
    width: 35em;
  }

  .buttons {
    margin-left: 0em;
    width: 17em;
  }
}

@media (min-width: 640px) and (max-width: 768px) {

  .field {
    height: auto;
    margin-left: 0;
    flex-direction: column;
  }
  .TEXT {
    margin-left: 0em;
    font-size: 18px;
    margin-right: 0;
  }

  .frameText {
    font-size: 2.8em;
  }

  .textdes{
    margin-right: 0;
  }

  .frames img {
    width: 35em;
  }

  .buttons {
    margin-left: 0em;
    width: 17em;
  }
}



@media (min-width: 360px) and (max-width: 640px) {
  .field {
    flex-direction: column;
    height: auto;
    width: 10em;
  }

  .TEXT {
    margin-left: 4em;
    font-size: 11px;
    margin-right: 0;
  }

  .frames img {
    width: 65%;
  }

  .frameText {
    width: 7em;
  }

  .frames img {
    width: 22em;
  }

  .frame {
    margin-top: 17%;
    margin-left: 17%;
  }

  .textdes {
    width: 15.5em;
    margin-right: 0em;
    font-size: 18px;
  }


  .buttons {
    margin-left: -5em;
    width: 17em;
  }

}


@media (min-width: 280px) and (max-width: 360px) {

  .field {
    flex-direction: column;
    height: auto;
    width: 10em;
    margin-left: 0.1%;
  }

  .TEXT {
    font-size: 11px;
    margin-top: 7em;
    margin-right: 1.5em;
  }

  .frames img {
    width: 140%;
    margin-top: 2em;
  }

  .frameText {
    width: 9em;
    font-size: 27px;
  }

  .textdes {
    width: 14.5em;
    margin-right: -3em;
  }



  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: column;
    margin-top: 0.4em;
    margin-left: -5em;
    width: 19em;
  }



  .btnOur {
    height: 35px;
    margin-top: 10%;
    margin-left: 5%;
  }


  .btnUs { 
    height: 35px;
    margin-top: 1.2em;
  }

}
#app {
    height: 100%
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #F2F4F6;
    ;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}


.testHead{
    display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  
}

.testimonial {
    width: 100%;
    height: 100dvh;
    background-color: #111111;
}

.testimonial .swiper {
    width: 100%;
    padding-top: 5%;
    /* padding-bottom: 50px; */
}

.testimonial .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 500px;
    background-color: #F2F4F6;
}

.testimonial .swiper-slide-active {
    text-decoration: none;
}

/* .testimonial .swiper-slide img {
  display: block;
  width: 100%;
} */

.slidercontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0%;
    margin-top: 25%;
    height: auto;

}

/* .view {
    display: flex;
    flex-direction: row;
} */

.viewdescription {
    margin: 5%;
    margin-top: 6%;
    font-style: work sans;
    font-size: 16px;
}

.company {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    width: 100%;
    margin-top: 5%;
}

.company div {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    margin: 2%;
}

.company div h1 {
    font-size: 16px;
}

.company div h3 {
    font-size: 12px;
    font-weight: 500
}

.kontent {
    margin: 2%;
    width: 16.5%;
    border-radius: 10px;
}

.ngo {
    margin: 2%;
    width: 16%;
    border-radius: 10px;

}

.cipher {
    margin: 2%;
    width: 28%;
    border-radius: 10px;
}


.baba {
    margin: 2%;
    width: 45%;
    height: 70px;

}


@media (min-width:1065px) and (max-width: 1368px) {

    .slidercontent {


        margin-top: 25%;

    }
}

@media (min-width:768px) and (max-width: 1064px) {

    .slidercontent {

        margin-top: 25%;

    }

    .testimonial .swiper {

        padding-top: 19%;

    }
}

@media (min-width: 640px) and (max-width: 768px) {


    .slidercontent {

        margin-top: 25%;

    }

    .testimonial .swiper {

        padding-top: 14%;

    }
}

@media (min-width: 480px) and (max-width:640px) {

    .slidercontent {

        margin-top: 25%;

    }

    .testimonial .swiper {

        padding-top: 12%;

    }
}

@media (min-width: 360px) and (max-width:480px) {

    .slidercontent {

        margin-top: 25%;

    }

    .testimonial .swiper {

        padding-top: 12%;

    }
}

@media (min-width: 280px) and (max-width:360px) {

    .slidercontent {

        margin-top: 25%;

    }

    .testimonial .swiper {

        padding-top: 17%;

    }
}

.projects{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    margin: 0% 10% 0% 10%;
}

.framesp{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projectheading{
    margin-top: 8%;
    margin-bottom: 8%;
}

.projectheading h1{
    font-style: work sans;
    font-weight: 600;
    font-size: 36px;
}

.projectimage{
    width: 90%;
    margin-bottom: 5%;
    display: flex; 
    flex-wrap: wrap;
}

.imagerow{
    flex: 25% 1;
}

.tilehover img{
    width: 100%;
    /* display: block; */
}

.tilehover{
  position: relative;
  margin: 2%;
}


.bottom{
  position: absolute; 
  bottom: 0; 
  
  background: rgba(167, 164, 164, 0.5); /* Black see-through */
  color: #e9e4e4; 
  transition: .5s ease;
  opacity:0;
  width: 100%;
  font-size: 10px;
  /* padding: 20px; */
  text-align: center;
  bottom: -40px;
  overflow: hidden;
}
.tilehover:hover .bottom{
  opacity: 1;
  bottom: 2px;
}

@media (min-width:480px) and (max-width: 1064px){
  .imagerow{
    flex: 50% 1;
}
}

@media (min-width: 360px) and (max-width:480px){
  .imagerow{
    flex: 100% 1;
}
}

.font-face-sa {
  font-family: "Saudagar";
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans';
}

body {
  background-color: #F2F4F6;
}

::-webkit-scrollbar {
  width: 7px;

}

/* Track */
::-webkit-scrollbar-track {

  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00618E;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #111111;
}

/* .............................marquee css start................... */

/* .backgroundanimation {
  overflow: hidden;
}

.marqi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  height: 1200px;
  width: 100vw;
  overflow: hidden;
  margin: auto;
}

.rfm-marquee-container{
  overflow: visible!important;
}

.marqee {
  width: 100%;
  z-index: 0;
  margin-left: -10%;
  margin-right: -10%;
}

.marqi::-webkit-scrollbar {
  display: none;

} */

.boxrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.boxcol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.founder div,
.foots {
  position: relative;
  z-index: 1;
}

/* .............................marquee css complete................... */


.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 1% 2%;
  background-color: #F2F4F6;
  z-index: 2;
  width: 100%;
  height: 2rem;
  padding: 2.4em 0em 2% 1.7em;
  margin-bottom: 0;
}


.nav-btn {
  cursor: pointer;
  background: transparent;
  border: none;
  visibility: hidden;
  opacity: 0;

}

.navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.menu-link {
  display: flex;
  flex-direction: row;
  margin-top: 11px;
}


.nav-item {
  list-style-type: none;
  margin-right: 0.4em;
  position: relative;
}

.navs {
  margin: auto;
}

.active {
  color: #464646;
  font-style: Work Sans;
  font-weight: 400;
  font-size: 1.3em;
  text-decoration: none;
  margin-left: 3.125em;
  position: relative;
  top: 30%;
}

.setlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}



.setlogo2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: none;
}

.setlogo2 .logo {
  width: 8em;
  cursor: pointer;
  margin-left: -32.88em;
  margin-top: 0em;
}

.setlogo2 .logoname {
  color: #464646;
  font-weight: 800;
  font-size: 1.176em;
  text-decoration: none;
  margin-left: -0.5em;
  margin-top: 0em;
}

.logo {
  width: 6em;
  cursor: pointer;
  margin-left: -17.88em;
  margin-bottom: -1.58em;
}

.logoname {
  color: #464646;
  font-weight: 800;
  font-size: 1.46em;
  text-decoration: none;
  margin-left: -0.5em;
  margin-bottom: -0.5em;
}

/* .we {
  color: #00618E;
} */

.newLink{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grow {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.grow:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  color: #00618E;
}


.btn {
  position: relative;
  left: 18%;
  background-color: #00618E;
  color: #F2F4F6;
  border-radius: 0.925em;
  box-shadow: 0em 0.05em 0.575em #00618E;
  width: 9.9em;
  height: 3.3em;
  border: none;
  cursor: pointer;
  font-family: Work Sans;
  /* margin-top: 0.9em; */
}

.btn a{
  color: #F2F4F6;
  text-decoration: none;
}


.mainContent {
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.main-1 {
  margin-left: 5%;
  width: 55%;
}

h5 {
  font-family: Work Sans;
  font-weight: 500;
  width: 80%;
  padding-top: 8%;
  line-height: 1.75em;
  font-size: 1.125em;
}

.btn_2 {
  background-color: #00618E;
  color: #F2F4F6;
  border-radius: 0.625em;
  border: none;
  width: 25%;
  height: 3.895em;
  cursor: pointer;
  position: relative;
  left: -22%;
  top: 0em;
}

.start {
  padding-top: 5%;
}

.form {
  border: none;
  width: 70%;
  height: 4.0625em;
  border-radius: 0.625em;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.06);
  padding-left: 2em;
}


.main-2 {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile {
  margin-top: 20%;
  width: 35%;
}

.laptop {
  margin-right: -15%;
  margin-bottom: 8%;
  width: 50%;
}



.firstText {
  -webkit-text-decoration: underline 6px #00618E;
          text-decoration: underline 6px #00618E;
  font-size: 60px;
  font-weight: 300;
  font-family: 'Work Sans';
  margin-top: 1em;
}


.main-1 div {
  margin-top: 0;
  display: flex;
  flex-direction: row;
}

.secondText {
  -webkit-text-decoration: underline 6px #7fb3d7;
          text-decoration: underline 6px #7fb3d7;

  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 65px;
  padding-top: 3%;
}


.why_we {
  background-color: #232323;
  color: #F2F4F6;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 140px;
  width: 100%;
}

.numbers {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
}

.number_description {
  font-family: 'Work sans';
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 24px;
  text-align: center;
}

.whyCaro {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin: 3%;
}


.textWhy {
  font-size: 54px;
  -webkit-text-stroke-width: 0.05px;
}

.ourWork {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-left: 10%;
  margin-right: 10%;
  color: #F2F4F6;
  background-color: #00618E;
  border-radius: 50px 50px 0 0;
  height: 53vh;
}

.ourWork2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textOur {
  margin: 0 1% 0 1%;
  color: #F2F4F6;
  font-size: 48px;
  font-weight: 400;
}

.ourWork h4 {
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 3%;

}

.flow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flow div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'work sans';
}

.flow div div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'work sans';
}

.circle {
  margin-top: 7%;
  text-align: center;
  font-family: 'work sans';
}

.circle h3 {
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  font-family: 'work sans';
}

.circle h4 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'work sans';
}


.circle1 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #F2F4F6;
  color: #00618E;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
}

/* .circle-desc-one {
  margin-left: 1.5em;
} */

.circle2 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #F2F4F6;
  color: #00618E;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-two-desc {
  /* margin-top: 1.35em; */
}

.circle3 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #F2F4F6;
  color: #00618E;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;

}

.circle4 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #F2F4F6;
  color: #00618E;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
}

/* .circle-desc {
  margin-right: 1em;
} */


.vector1 {
  width: 207px;
  height: 30px;
  margin-bottom: 20%;
}

.vector2 {
  width: 208px;
  height: 30px;
  margin-bottom: 28%;
}

.vector3 {
  width: 204px;
  height: 30px;
  margin-bottom: 20%;
}



/* ........Image Animation........ */

.imageAnimation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111111;
  /* height: 130vh; */
  flex-direction: column;
  overflow: hidden;
}

.export {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 0;
  margin-top: 2%;
}

.imgAni {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 42em;
  right: 6em;
  -webkit-transform: skew(1deg, 3deg);
          transform: skew(1deg, 3deg);
  margin: 5%;
}


.noImg1 {
  width: 50%;
  position: relative;
  top: -20em;
  left: -20em;
}

.noImg2 {
  width: 50%;
  position: relative;
  top: -30em;
  left: -5em;
}

.noImg3 {
  width: 50%;
  position: relative;
  top: -40em;
  left: 15em;

}

.noImg4 {
  width: 50%;
  position: relative;
  top: -50em;
  left: 30em;

}

.export h1 {
  -webkit-text-stroke-width: 2px;
  color: #F2F4F6;
  -webkit-text-fill-color: transparent;
  font-size: 388px;
  font-weight: 400;
  line-height: 550px;
}


.export h3 {
  font-family: Work Sans;
  color: #F2F4F6;
  font-size: 64px;
  font-weight: 400;
  line-height: 85px;
  margin-top: -13%;
}

/* .......slider........ */


.founder {
  margin-top: 2%;
  margin-bottom: 1%;
  background: #F2F4F6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.founder div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.founderheading {
  margin-top: 3%;
  margin-bottom: 3%;
}

.founderheading h1 {
  font-style: work sans;
  font-weight: 600;
  font-size: 52px;
}

.founderimage {
  width: 30%;
  border: 0.1px solid rgb(194, 187, 187);
  border-radius: 10px;
  margin-bottom: 5%;
}

.founderimage div {
  width: 100%;
}

.founderimage div img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;

}


.founderimage div h1 {
  font-family: work sans;
  font-weight: 500;
  font-size: 24px;
  margin: 1%;
}

.founderimage div h2 {
  font-family: work sans;
  font-weight: 400;
  font-size: 16px;
}

.founderimage div p {
  width: 90%;
  text-align: center;
  font-size: 16px;
  margin: 1%;
}

.founderimage div div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 7%;
}

.founderimage div div a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.founderimage div div a img {
  margin: 2%;
  width: 40%;
}



/* .......Footer........ */


#foot {
  background: #F2F4F6;
}

.illust {
  background: #F5F5F5;
  height: 360px;
  display: flex;
  align-items: end;
  justify-content: end;
}



.mans {
  width: 20%;
  margin-right: 10%;
}



/* footer */

footer {
  width: 90%;
  height: 300px;
  margin: 70px 70px 0 70px;
  background: #00618E;
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

}

footer div {
  display: flex;
  flex-direction: row;
}


.circles {
  width: 100%;
  height: 500px;
  border-radius: 50%;
  margin-top: -43.5%;
  margin-left: -12%;
  background: #F2F4F6;
  display: flex;
  flex-direction: column;
}

.logo-f {
  display: flex;
  flex-direction: row;
}

.logo-f img {
  margin-left: 16%;
  margin-top: 50%;
  width: 20%;
}

.SC {
  margin-left: 5%;
  margin-top: 51%;
  color: #232323;
  font-size: 35px;
  font-weight: 500;
  /* line-height: 90px; */
}

.circles p {
  position: relative;
  width: 322px;
  left: 21%;
  top: 5%;

  font-family: 'Work Sans';
  font-size: 16px;
  line-height: 20px;
  color: #464646;
}

.col-1 div p {
  margin-top: -40px;
  margin-left: -15px;
}

footer .col-2 {
  display: flex;
  justify-content: space-between;
  position: relative;
  left: 7%;
}

.col-2 {
  display: flex;
  flex-direction: column;
}

.col-2 div div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -10%;
}

hr {
  height: 1.5px;
  width: 85%;
  background-color: #F2F4F6;
  border-radius: 10PX;
  /* margin: 1%; */

}

.col-2 div div p {
  color: #F2F4F6;
  font-family: work sans;
  padding: 2% 2% 2% 0%;
  font-weight: 400;
  font-size: 16px;
}

footer .col-2 .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 6%;
  margin-right: 150px;
  /* margin-left: 12; */
}

footer .col-2 .col h4 {
  padding-bottom: 20%;
  font-weight: 600;
  font-size: 24px;
  line-height: 3%;
  color: #FFFFFF;
}

footer .col-2 .col a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2%;
  font-size: 14px;
  /* line-height: 35%; */

  /* gap: 12px; */
  text-decoration: none;
  /* height: 35px; */
  color: #E5E8EA;

  ::-webkit-scrollbar {
    width: 7px;

  }

  /* Track */
  ::-webkit-scrollbar-track {

    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00618E;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #111111;
  }

}



/* !!!!!!!!!!!!!!!!!!.........RESPONSIVE..........!!!!!!!!!!!!!!!!! */

@media (min-width:768px) and (max-width:835px) {

  .responsive_nav {
    -webkit-transform: none;
            transform: none;
    height: 100vh;
  }

  .setlogo2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 16em;
    margin-top: 0em;
    display: flex;
  }

  .setlogo2 .logo {
    width: 5.75em;
    cursor: pointer;
    margin-left: -18.88em;
    margin-top: 0em;
  }

  .setlogo2 .logoname {
    color: #464646;
    font-weight: 800;
    font-size: 2em;
    text-decoration: none;
    margin-left: 0em;
    margin-top: 0em;
  }

  .nav-btn {
    padding: 5px;
    visibility: visible;
    opacity: 1;
    font-size: 1.8rem;
    margin-left: 80%;
    margin-top: 0rem;
    display: flex;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: #F2F4F6;
    transition: 1s;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }

  nav a {
    font-size: 1.5rem;
    padding-top: 4rem;
  }

  .menu-link {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    width: 80rem;
    margin-left: -20%;
    background-color: #F2F4F6;
  }

  .btn {
    margin-left: 34.5%;
    margin-bottom: 2rem;
    margin-top: 2.5em;
  }


  nav .nav-close-btn {
    position: absolute;
    top: 6rem;
    right: 12%
  }

  .active {
    margin-top: 2.5px;
    margin-left: 10.825em;
    font-size: 18px;
  }

  .setlogo {
    display: none;
  }

  .logo {
    width: 55px;
    margin-top: 8%;
  }

  .logoname {
    font-weight: bolder;
    font-size: 18px;
    margin-top: 4%;
  }


  .export h1 {
    margin-top: -25%;
    font-size: 250px;
    line-height: 550px;
  }

  .export h3 {
    font-size: 46px;
    line-height: 95px;
    margin-top: -30%;
  }

  .noImg1 {
    top: -25em;
    left: -5em;
  }

  .noImg2 {
    top: -33em;
    left: 2em;
  }

  .noImg3 {
    top: -41em;
    left: 10em;

  }

  .noImg4 {
    top: -49em;
    left: 22em;

  }

  .illust {
    height: 281.5px;
  }


  .mans {
    width: 26%;
    top: 44%;
    left: 8%;
  }


  .number_description {
    font-size: 12px;
  }

  .proje {
    padding: 2%;
  }

  .ourWork {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 3%;
    margin-right: 3%;
    color: #F2F4F6;
    background-color: #00618E;
    border-radius: 50px 50px 0 0;
    height: 53vh;
  }

  .textOur {
    margin: 0 1% 0 1%;
    color: #F2F4F6;
    font-size: 35px;
    font-weight: 400;
  }

  .ourWork h4 {
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 3%;

  }

  .flow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .flow div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'work sans';
  }

  .flow div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'work sans';
  }

  .circle {
    margin-top: 7%;
    text-align: center;
    font-family: 'work sans';
  }

  .circle h3 {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 700;
    line-height: 28px;
    font-family: 'work sans';
  }

  .circle h4 {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'work sans';
  }


  .circle1 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5em;
  }

  .circle-desc-one {
    margin-left: 1.5em;
  }

  .circle2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-two-desc {
    /* margin-top: 1.35em; */
  }

  .circle3 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .circle4 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;
  }

  .circle-desc {
    margin-right: 1em;
  }


  .vector1 {
    width: 90px;
    height: 30px;
    margin-bottom: 20%;
  }

  .vector2 {
    width: 90px;
    height: 30px;
    margin-bottom: 28%;
  }

  .vector3 {
    width: 90px;
    height: 30px;
    margin-bottom: 20%;
  }


  .section-1 .main-container .box {
    width: 10em;
    height: 17em;
  }

  .section-1 p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px
  }
  .mainContent {
    margin-top: -2%;
    margin: 0;
    height: 150vh;
  }

}

@media (min-width:835px) and (max-width:926px) {

  .mainContent {
    margin-top: -2%;
    height: 130vh;
  }

  .setlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 14px;
  }


  .logo {
    width: 60px;
    margin-top: 0%;
    width: 4.75em;
    cursor: pointer;
    margin-left: -6em;
  }

  .logoname {
    font-size: 1em;
    margin-top: 3%;
    color: #464646;
    font-weight: 800;
    font-size: 1.176em;
    text-decoration: none;
    margin-left: -0.5em;
  }

  .menu-link {
    padding-left: 28px;
  }

  .btn {
    left: 10%;
  }

  .active {
    font-size: 15px;
    margin-left: 15px;
    top: 15%;
  }

  .export h1 {
    margin-top: -25%;
    font-size: 270px;
    line-height: 550px;
  }

  .navbar {
    height: 2rem;
  }

  .export h3 {
    font-size: 56px;
    line-height: 95px;
    margin-top: -25%;
  }

  .noImg1 {
    top: -25em;
    left: -5em;
  }

  .noImg2 {
    top: -33em;
    left: 2em;
  }

  .noImg3 {
    top: -41em;
    left: 10em;

  }

  .noImg4 {
    top: -49em;
    left: 22em;

  }

  .illust {
    height: 327px;
  }

  .proje {
    padding: 2%;
  }

  .number_description {
    font-size: 12px;
  }


  .ourWork {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 3%;
    margin-right: 3%;
    color: #F2F4F6;
    background-color: #00618E;
    border-radius: 50px 50px 0 0;
    height: 53vh;
  }

  .textOur {
    margin: 0 1% 0 1%;
    color: #F2F4F6;
    font-size: 35px;
    font-weight: 400;
  }

  .ourWork h4 {
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 3%;

  }

  .flow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .flow div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'work sans';
  }

  .flow div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'work sans';
  }

  .circle {
    margin-top: 7%;
    text-align: center;
    font-family: 'work sans';
  }

  .circle h3 {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 700;
    line-height: 28px;
    font-family: 'work sans';
  }

  .circle h4 {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'work sans';
  }


  .circle1 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5em;
  }

  .circle-desc-one {
    margin-left: 1.5em;
  }

  .circle2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-two-desc {
    /* margin-top: 1.35em; */
  }

  .circle3 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .circle4 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;
  }

  .circle-desc {
    margin-right: 1em;
    margin-bottom: 1.6em;
  }


  .vector1 {
    width: 107px;
    height: 30px;
    margin-bottom: 20%;
  }

  .vector2 {
    width: 108px;
    height: 30px;
    margin-bottom: 28%;
  }

  .vector3 {
    width: 104px;
    height: 30px;
    margin-bottom: 20%;
  }


  .section-1 .main-container .box {
    width: 10em;
    height: 17em;
  }

  .section-1 p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px
  }

  /* .circles h3 {
    font-size: 23px;
    margin-left: 2em;
  }

  .circles p {
    font-size: 13px;
    width: 13em;
    margin-left: 5em;
  } */

  /* footer div {
    font-size: 12px;
    margin-left: -4em;
  } */
}

@media (min-width:926px) and (max-width:1064px) {
  .mainContent {
    margin-top: -2%;
  }

  .setlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 15px;
    margin-left: em;
  }

  .navbar {
    height: 2rem;
  }

  .logo {
    width: 4.75em;
    cursor: pointer;
    margin-left: -5em;
  }

  .menu-link {
    padding-left: 20px;
  }

  .logoname {
    font-size: 1em;
    margin-top: 3%;
    color: #464646;
    font-weight: 800;
    font-size: 1.176em;
    text-decoration: none;
    margin-left: -0.5em;
  }

  .btn {
    left: 7%;
  }

  .active {
    font-size: 1.1em;
    margin-left: 30px;
    top: 15%;
  }

  .export h1 {
    margin-top: -25%;
    font-size: 290px;
    line-height: 550px;
  }

  .export h3 {
    font-size: 66px;
    line-height: 95px;
    margin-top: -20%;
  }

  .noImg1 {
    top: -25em;
    left: -5em;
  }

  .noImg2 {
    top: -33em;
    left: 2em;
  }

  .noImg3 {
    top: -41em;
    left: 10em;

  }

  .noImg4 {
    top: -49em;
    left: 22em;

  }

  .illust {
    height: 350.5px;
  }




  .ourWork {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 3%;
    margin-right: 3%;
    color: #F2F4F6;
    background-color: #00618E;
    border-radius: 50px 50px 0 0;
    height: 53vh;
  }

  .textOur {
    margin: 0 1% 0 1%;
    color: #F2F4F6;
    font-size: 35px;
    font-weight: 400;
  }

  .ourWork h4 {
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 3%;

  }

  .flow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .flow div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'work sans';
  }

  .flow div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'work sans';
  }

  .circle {
    margin-top: 7%;
    text-align: center;
    font-family: 'work sans';
  }

  .circle h3 {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 700;
    line-height: 28px;
    font-family: 'work sans';
  }

  .circle h4 {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'work sans';
  }


  .circle1 {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5em;
  }

  .circle-desc-one {
    margin-left: 1.5em;
  }

  .circle2 {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-two-desc {
    /* margin-top: 1.35em; */
  }

  .circle3 {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .circle4 {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #F2F4F6;
    color: #00618E;
    font-family: 'Inter';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;
  }

  .circle-desc {
    margin-right: 1em;
  }


  .vector1 {
    width: 107px;
    height: 30px;
    margin-bottom: 20%;
  }

  .vector2 {
    width: 108px;
    height: 30px;
    margin-bottom: 28%;
  }

  .vector3 {
    width: 104px;
    height: 30px;
    margin-bottom: 20%;
  }


  .section-1 .main-container .box {
    width: 12em;
  }

  .section-1 p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px
  }

  /* .circles h3 {
    font-size: 23px;
    margin-left: 2em;
  }

  .circles p {
    font-size: 13px;
    width: 13em;
    margin-left: 5em;
  }

  footer div {
    font-size: 12px;
    margin-left: -4em;
  } */
}

@media (min-width:1065px) and (max-width: 1368px) {

  .marqi {
    height: 1500px;
  }

  .setlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-right: -18rem;
  }

  .logo {
    width: 5em;
    cursor: pointer;
    margin-left: -22.88em;
    margin-bottom: -1.58em;
  }

  .logoname {
    color: #464646;
    font-weight: 800;
    font-size: 1.46em;
    text-decoration: none;
    margin-left: -0.5em;
    margin-bottom: -0.5em;
  }

  .menu-link {
    padding-left: 57px;
  }

  .navbar-nav {
    width: 100%;
  }

  .nav-item {
    position: relative;
  }

  .active {
    font-size: 1.23em;
    top: 15%;
  }

  .navbar {
    height: 2rem;
  }

  .btn {
    left: 7%;
    margin-top: 0.1em;
  }

  .mainContent {
    height: auto;
    padding-top: 10em;
    padding-bottom: 5em;
  }

  .firstText {
    font-size: 27px;
  }

  .secondText {
    font-size: 34px;
  }

  h5 {
    padding-top: 12%;
    font-size: 15px;
  }


  .btn_2 {
    width: 33%;
    height: 3.5em;
    cursor: pointer;
    position: relative;
    left: 50%;
    top: -46px;
  }


  .form {
    width: 80%;
    height: 45px;
  }

  .laptop {
    margin-left: 5%;
    margin-bottom: 15%;
    width: 55%;
  }



  /* ............... WHY WE ........... */

  .numbers {
    font-size: 32px;
  }

  .number_description {
    font-size: 14px;
  }

  .textWhy {
    font-size: 44px;
    -webkit-text-stroke-width: 0.05px;
  }

  .swiper-slide-active {
    font-size: 40px;
  }

  /* ............ OUR WORK FLOW .............. */

  .ourWork {
    height: 45vh;
  }

  .textOur {
    font-size: 45px;
  }

  .ourWork h4 {
    line-height: 30px;
    margin-bottom: 3%;
  }

  .circle {
    margin-top: 10%;
  }

  .circle h3 {
    font-size: 15px;
    line-height: 24px;
  }

  .circle h4 {
    font-size: 12px;
    line-height: 17px;
  }

  .circle1 {
    width: 50px;
    height: 50px;
  }

  .circle2 {
    width: 50px;
    height: 50px;
  }

  .circle3 {
    width: 50px;
    height: 50px;
  }

  .circle4 {
    width: 50px;
    height: 50px;
  }

  .vector1 {
    width: 30%;
    height: 10px;
    /* margin-bottom: 35%; */
  }

  .vector2 {
    width: 30%;
    height: 10px;
    /* margin-bottom: 45%; */
  }

  .vector3 {
    width: 30%;
    height: 10px;
    /* margin-bottom: 35%; */
  }

  /* ............. EXPORT......... */


  .imgAni {
    position: relative;
    top: 36em;
    right: 6em;
    opacity: 1;
    margin-left: 3%;

  }

  .export h3 {
    font-size: 54px;
    line-height: 85px;
    margin-top: -13.5%;
  }

  .export h1 {
    margin-top: -20%;
    font-size: 380px;
    line-height: 550px;
  }


  .noImg1 {
    top: -25em;
    left: -5em;
  }

  .noImg2 {
    top: -33em;
    left: 2em;
  }

  .noImg3 {
    top: -41em;
    left: 10em;

  }

  .noImg4 {
    top: -49em;
    left: 22em;

  }

  /* .............our team carousel............ */

  .founder {
    margin-top: 5%;
    margin-bottom: 9%;
    background: #F2F4F6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .founder div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .founderheading {
    margin-top: 3%;
    margin-bottom: 7%;
  }

  .founderheading h1 {
    font-style: work sans;
    font-weight: 600;
    font-size: 52px;
  }

  .founderimage {
    width: 40%;
    border: 0.1px solid rgb(194, 187, 187);
    border-radius: 10px;
  }

  .founderimage div {
    width: 100%;
  }

  .founderimage div img {
    border-radius: 10px 10px 0px 0px;
    width: 100%;

  }


  .founderimage div h1 {
    font-family: work sans;
    font-weight: 500;
    font-size: 24px;
    margin: 3%;
  }

  .founderimage div p {
    font-family: work sans;
    font-weight: 400;
    font-size: 16px;
  }

  .founderimage div div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 7%;
  }

  .founderimage div div a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .founderimage div div a img {
    margin: 10%;
    width: 40%;
  }

  /* ..............FOOTER............... */

  .circles {
    width: 113%;
    height: 429px;
    margin-top: -19.5%;
    margin-left: -17%;
  }

  footer {
    margin: 0 5%;
    height: 350px;
  }

  .illust {
    height: 395px;
  }

  .mans {
    width: 22%;
    margin-right: 10%;

  }


  .logo-f img {
    margin-left: 9%;
    margin-top: 20%;
    width: 33%;
  }

  .SC {
    margin-left: 0%;
    font-size: 30px;
    margin-top: 25.5%;
  }

  .circles p {
    width: 275px;
    left: 21%;
    top: -5%;
    font-family: 'Work Sans';
    font-size: 18px;
    line-height: 25px;
    color: #464646;
  }

  .col-1 div p {
    margin-top: -10px;
    margin-left: 5px;
    font-size: 12px;
  }

  footer .col-2 {
    left: 10%;
    top: 5%;
  }

  footer .col-2 .col {
    margin-top: 5%;
    margin-right: 120px;
  }

  footer .col-2 .col h4 {
    font-size: 21px;
    line-height: 6px;
    color: #ffffff;
  }

  footer .col-2 .col a {
    line-height: 20px;
    font-size: 14px;
  }

  .col-2 {
    display: flex;
    flex-direction: column;
  }

  .col-2 div div {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: -14%;
  }

  hr {
    height: 1.5px;
    width: 85%;
    background-color: #F2F4F6;
    border-radius: 10PX;
    /* margin: 1%; */

  }

  .col-2 div div p {
    color: #F2F4F6;
    font-family: work sans;
    padding: 2% 2% 2% 0%;
    font-weight: 400;
    font-size: 14px;
  }

}

@media (min-width:768px) and (max-width: 1064px) {

  .marqi {
    height: 1100px;
  }

  .mainContent {
    height: auto;
    padding-top: 10em;
    padding-bottom: 5em;
  }

  .firstText {
    font-size: 27px;
  }

  .secondText {
    font-size: 34px;
  }


  /* ............. EXPORT......... */


  .imgAni {
    position: relative;
    top: 39em;
    right: 6em;
    opacity: 1;
  }



  /* .............our team carousel............ */

  .founder {
    margin-top: 2%;
    margin-bottom: 3%;

  }

  .founderimage {
    width: 40%;
  }

  .founderheading {
    margin-top: 4%;
    margin-bottom: 7%;
  }

  .founderheading h1 {
    font-size: 46px;
  }



  /* ..............FOOTER............... */


  .circles {
    height: 345px;
    margin-top: -12.5%;
    margin-left: -16%;
  }

  footer {
    margin: 0 5%;
    height: 300px;
  }

  .logo-f img {
    margin-left: 15%;
    margin-top: 20%;
    width: 30%;
  }

  .SC {
    margin-left: 2%;
    font-size: 20px;
    margin-top: 25.5%;
  }

  .circles p {
    width: 225px;
    left: 21%;
    top: 0%;

    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 20px;
    color: #464646;
  }

  .col-1 div p {
    margin-left: 0px;
    margin-top: -20px;
  }

  footer .col-2 {
    left: 6%;
  }

  footer .col-2 .col {
    margin-top: 10%;
    margin-right: 56px;
  }

  footer .col-2 .col h4 {
    font-size: 16px;
    line-height: 6px;
    color: #ffffff;
    padding-bottom: 15%;
  }

  footer .col-2 .col a {
    line-height: 16px;
    font-size: 13px;
  }

  .col-2 div div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -12%;
  }

  .col-2 div div p {
    color: #F2F4F6;
    font-family: work sans;
    padding: 2% 2% 2% 0%;
    font-weight: 400;
    font-size: 12px;
  }

}

@media (min-width: 640px) and (max-width: 768px) {

  .marqi {
    height: 1100px;
  }

  .setlogo2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 16em;
    margin-top: 0em;
    display: flex;
  }

  .setlogo2 .logo {
    width: 5.75em;
    cursor: pointer;
    margin-left: -18.88em;
    margin-top: 0em;
  }

  .setlogo2 .logoname {
    color: #464646;
    font-weight: 800;
    font-size: 1.576em;
    text-decoration: none;
    margin-left: 0em;
    margin-top: 0em;
  }

  .nav-btn {
    padding: 5px;
    visibility: visible;
    opacity: 1;
    font-size: 1.5rem;
    margin-left: 80%;
    margin-top: 0rem;
    display: flex;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: #F2F4F6;
    transition: 1s;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }

  nav a {
    font-size: 1.5rem;
    padding-top: 4rem;
  }

  .menu-link {
    display: flex;
    flex-direction: column;
    /* margin-top: 40rem; */
    width: 80rem;
    margin-left: -20%;
    background-color: #F2F4F6;
  }

  .btn {
    margin-left: 34.5%;
    margin-bottom: 5rem;
    margin-top: 2.5em;
  }

  .navbar {
    height: 2rem;
  }

  .responsive_nav {
    -webkit-transform: none;
            transform: none;
    height: 100vh;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 6rem;
    right: 12%
  }

  .active {
    margin-top: 2.5px;
    margin-left: 10.825em;
    font-size: 18px;
  }

  .setlogo {
    display: none;
  }

  .logo {
    width: 55px;
    margin-top: 8%;
  }

  .logoname {
    font-weight: bolder;
    font-size: 18px;
    margin-top: 4%;
  }


  .mainContent {
    margin-left: 0%;
    height: auto;
    flex-direction: column;
  }

  .main-1 {
    margin-left: 0;
    width: 100%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main-2 {
    flex-direction: column;
  }

  .firstText {
    font-size: 50px;
    text-align: center;
  }

  .secondText {
    font-size: 58px;
    text-align: center;
  }

  h5 {
    padding-top: 9%;
    font-size: 17px;
    text-align: center;
  }

  .start {
    padding-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn_2 {
    width: 30%;
    cursor: pointer;
    position: relative;
    left: 28.5%;
    top: -51.5px;
  }

  .form {
    width: 80%;
    height: 51px;
  }

  .mobile {
    margin-top: 0%;
    margin-right: 0%;
    width: 75%;
    margin-bottom: 15%;
  }

  .laptop {
    display: none;
  }

  /* ............... WHY WE ........... */

  .why_we {
    flex-direction: column;
    height: 480px;
  }


  .textWhy {
    font-size: 30px;
    -webkit-text-stroke-width: 0.05px;
  }

  .whyCaro {
    margin: 10% 14%;
  }

  .swiper-slide-active {
    -webkit-text-decoration: underline 5px solid #111111;
            text-decoration: underline 5px solid #111111;
  }

  .slider {
    width: 66%;
  }


  /* ............ OUR WORK FLOW .............. */

  .flow {
    flex-direction: column;
    margin-bottom: 5%;
  }

  .flow div {
    flex-direction: column;
  }

  .ourWork {
    height: auto;
    margin-left: 7%;
    margin-right: 7%;
    border-radius: 35px 35px 0 0;
  }

  .textOur {
    font-size: 35px;
  }

  .vector1 {
    width: 30%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 15%;
    height: 15px;
  }

  .vector2 {
    width: 30%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 15%;
    height: 19px;
  }

  .vector3 {
    width: 30%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 15%;
    height: 15px;
  }

  .textOur {
    margin: 2%;
  }

  /* 

  

  .ourWork h4 {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 3%;
  }

  .circle {
    margin-top: 13%;
  }

  .circle h3 {
    font-size: 13px;
    line-height: 24px;
  }

  .circle h4 {
    font-size: 10px;
    line-height: 17px;
  }

  .circle1 {
    width: 50px;
    height: 50px;
  }

  .circle2 {
    width: 50px;
    height: 50px;
  }

  .circle3 {
    width: 50px;
    height: 50px;
  }

  .circle4 {
    width: 50px;
    height: 50px;
  }

  .vector1 {
    width: 30%;
    height: 10px;
    margin-bottom: 50%;
  }

  .vector2 {
    width: 30%;
    height: 10px;
    margin-bottom: 55%;
  }

  .vector3 {
    width: 30%;
    height: 10px;
    margin-bottom: 50%;
  } */

  /* ............. EXPORT......... */

  .imageAnimation {
    height: 109vh;
  }

  .imgAni {
    position: relative;
    top: 39em;
    right: 7em;
    opacity: 1;
  }

  .export {
    margin-top: -25%;
  }

  .export h1 {
    font-size: 220px;
    font-weight: 400;
    line-height: 550px;
  }

  .export h3 {
    font-size: 36px;
    line-height: 95px;
    margin-top: -37%;
  }

  .noImg1 {
    top: -35em;
    left: -5em;
  }

  .noImg2 {
    top: -37em;
    left: 2em;
  }

  .noImg3 {
    top: -39em;
    left: 10em;

  }

  .noImg4 {
    top: -41em;
    left: 19em;

  }


  /* .............our team carousel............ */

  .founder {
    margin-top: 7%;
    margin-bottom: 10%;
  }

  .founderheading {
    margin-top: 3%;
    margin-bottom: 10%;
  }

  .founderimage {
    width: 55%;
  }

  .founderheading h1 {
    font-size: 44px;
  }

  /* ..............FOOTER............... */

  .circles {
    width: 106%;
    height: 357px;
    margin-top: -15.5%;
    margin-left: -22%;
  }

  footer {
    margin: 0 5%;
    height: 300px;
  }

  .logo-f img {
    margin-left: 18%;
    margin-top: 20%;
    width: 27%;

  }

  .SC {
    margin-left: 0%;
    font-size: 20px;
    margin-top: 24.5%;
  }

  .circles p {
    width: 200px;
    left: 26%;
    top: 0%;

    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 20px;
    color: #464646;
  }

  .col-1 div p {
    margin-top: -20px;
    margin-left: 0px;
  }

  .illust {
    height: 281.5px;
  }



  .mans {
    width: 40%;
    margin-right: 10%;

  }

  footer .col-2 {
    left: 1%;
  }

  footer .col-2 .col {
    margin-top: 15%;
    margin-right: 30px;
  }

  footer .col-2 .col h4 {
    font-size: 14px;
    line-height: 0px;
    padding-bottom: 24%;
    color: #ffffff;
  }

  footer .col-2 .col a {
    line-height: 16px;
    font-size: 12px;
  }

  .col-2 div div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -17%;
  }

  .col-2 div div p {
    color: #F2F4F6;
    font-family: work sans;
    padding: 2% 2% 2% 0%;
    font-weight: 400;
    font-size: 10px;
  }
}


@media (min-width: 361px) and (max-width:640px) {

  .marqi {
    height: 1100px;
  }

  .setlogo2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 16em;
    margin-top: 0em;
    display: flex;
  }

  .setlogo2 .logo {
    width: 5.75em;
    margin-left: -18.88em;
    cursor: pointer;
    margin-top: 0em;
  }

  .setlogo2 .logoname {
    color: #464646;
    font-weight: 800;
    font-size: 1.56em;
    text-decoration: none;
    margin-left: 0em;
    margin-top: 0em;
  }

  .nav-btn {
    padding: 5px;
    visibility: visible;
    opacity: 1;
    font-size: 1.5rem;
    margin-left: 80%;
    margin-top: 0rem;
    display: flex;
  }


  nav {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: #F2F4F6;
    transition: 1s;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }

  nav a {
    font-size: 1.5rem;
    padding-top: 5rem;
  }

  .menu-link {
    display: flex;
    flex-direction: column;
    /* margin-top: 40rem; */
    width: 80rem;
    margin-left: -20%;
    background-color: #F2F4F6;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 17%;
    right: 15%;
  }

  .btn {
    margin-left: 27.7rem;
    margin-bottom: 3rem;
    margin-top: 3em;
  }

  .navbar {
    height: 2rem;
  }

  .responsive_nav {
    -webkit-transform: none;
            transform: none;
    height: 100vh;
  }


  .active {
    margin-top: 2.5px;
    margin-left: 10.825em;
    font-size: 18px;
  }

  .mainContent {
    margin-left: 0%;
    height: auto;
    flex-direction: column;
  }

  .main-1 {
    margin-left: 0;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .main-2 {
    flex-direction: column;
  }

  .firstText {
    font-size: 27px;
    text-align: center;
  }

  .secondText {
    font-size: 36px;
    text-align: center;
  }

  h5 {
    padding-top: 9%;
    font-size: 16px;
    text-align: center;
  }

  .start {
    padding-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .setlogo {
    display: none;
  }

  .btn_2 {
    width: 38%;
    height: 3.4375em;
    cursor: pointer;
    position: relative;
    left: 24%;
    top: -45px;
  }

  .form {
    width: 50%;
    margin-left: -34%;
    height: 45px;
    padding-left: 2%;
    font-size: 11px;
  }

  .mobile {
    margin-top: 0%;
    margin-right: 0%;
    width: 75%;
    margin-bottom: 15%;
  }

  .laptop {
    display: none;
  }

  form.start.aos-init.aos-animate {
    width: 60%;
  }

  /* ............... WHY WE ........... */

  .why_we {
    flex-direction: column;
    height: 480px;
    width: 100%;
  }

  .circle1, .circle2, .circle3, .circle4{
    width: 40px;
    height: 40px;
    font-size: 10px;
  }

  .textWhy {
    font-size: 25px;
    -webkit-text-stroke-width: 0.05px;
  }

  .whyCaro {
    margin: 10% 0%;
  }

  .swiper-slide-active {
    -webkit-text-decoration: underline 2px solid #111111;
            text-decoration: underline 2px solid #111111;
  }

  .slider {
    width: 66%;
  }


  /* ............ OUR WORK FLOW .............. */


  .flow {
    flex-direction: column;
    margin-bottom: 5%;
  }

  .flow div {
    flex-direction: column;
  }

  .ourWork {
    height: auto;
    margin-left: 7%;
    margin-right: 7%;
    border-radius: 25px 25px 0 0;
  }

  .ourWork h4 {
    text-align: center;
    font-size: 16px;
  }

  .textOur {
    font-size: 35px;
    margin: 2%;
  }

  .vector1 {
    width: 30%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 15%;
    height: 12px;
  }

  .vector2 {
    width: 40%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 10%;
    height: 15px;
  }

  .vector3 {
    width: 30%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 15%;
    height: 12px;
  }

  /* ............. EXPORT......... */

  .imageAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111111;
    height: 102vh;
    flex-direction: column;
  }

  .imgAni {
    position: relative;
    top: 39em;
    right: 7em;
    opacity: 1;
  }

  .export {
    margin-top: -70%;
  }

  .export h1 {
    font-size: 115px;
    font-weight: 400;
    line-height: 550px;
  }

  .export h3 {
    font-size: 22px;
    line-height: 95px;
    margin-top: -86%;
    margin-left: 5%;
  }

  .noImg1 {
    width: 70%;
    top: -40em;
    left: 3em;
  }

  .noImg2 {
    width: 70%;
    top: -41em;
    left: 5em;
  }

  .noImg3 {
    width: 70%;
    top: -41em;
    left: 9em;

  }

  .noImg4 {
    width: 70%;
    top: -42em;
    left: 12em;

  }


  /* .............our team carousel............ */

  .founder {
    margin-top: 7%;
    margin-bottom: 10%;
  }

  .founderheading {
    margin-top: 6%;
    margin-bottom: 10%;
  }

  .founderimage {
    width: 65%;
  }

  .founderheading h1 {
    font-size: 40px;
  }

  /* ..............FOOTER............... */

  .circles {
    height: 440px;
    margin-top: -30%;
    margin-left: -5%;
    width: 130%;
  }

  footer {
    border-radius: 14px 14px 0px 0px;
    margin: 5% 0 0 5%;
    height: 300px;
  }

  .col-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .col-1 div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col-1 div p {
    margin-left: 20px;
    font-size: 12px;
    margin-top: -20px;
  }

  .logo-f{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-f img {
    margin-left: -8%;
    margin-top: 20%;
    width: 30%;
  }

  .SC {
    margin-left: 0%;
    font-size: 30px;
    margin-top: 8%;
  }

  .circles p {
    width: 60%;
    left: 0%;
    top: 0%;

    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 20px;
    color: #464646;
  }

  footer {
    height: auto;
  }

  footer div {
    display: flex;
    flex-direction: column;
  } 

  footer .col-2 .col {
    margin-top: 15%;
    margin-right: 0px;
  }

  footer .col-2 .col h4 {
    font-size: 26px;
    line-height: 6px;
    color: #ffffff;
    padding-bottom: 8%;
  }

  footer .col-2 .col a {
    line-height: 20px;
    font-size: 18px;
  }

  .col-2 div div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .col-2 div div p {
    color: #F2F4F6;
    font-family: work sans;
    padding: 2% 2% 2% 0%;
    font-weight: 400;
    font-size: 11px;
  }

}

@media (min-width:280px) and (max-width: 360px) {
  .setlogo {
    display: none;

  }

  .setlogo2 {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 0;
    display: flex;
    /* width: 12em; */
    margin-bottom:0;
  }

  .setlogo2 .logo {
    width: 5.75em;
    cursor: pointer;
    margin-left: -2em;
    margin-top: 0em;
  }

  .setlogo2 .logoname {
    color: #464646;
    font-weight: 800;
    font-size: 1.2em;
    text-decoration: none;
    margin-left: 0em;
    margin-top: 0em;
  }

  .nav-btn {
    padding: 5px;
    visibility: visible;
    opacity: 1;
    font-size: 1.2rem;
    margin-top: 0rem;
    margin-left: 20%;
    display: flex;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: #F2F4F6;
    transition: 1s;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }

  nav a {
    font-size: 1.5rem;
    padding-top: 5rem;
  }

  .menu-link {
    display: flex;
    flex-direction: column;
    /* margin-top: 38rem; */
    width: 80rem;
    margin-left: -20%;
    background-color: #F2F4F6;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 17%;
    right: 15%;
  }

  .btn {
    margin-left: 27.7rem;
    margin-bottom: 2rem;
    margin-top: 3em;
  }

  .navbar {
    height: 2rem;
  }

  .responsive_nav {
    -webkit-transform: none;
            transform: none;
    height: 100vh;
  }

  form.start.aos-init.aos-animate {
    width: 60%;
  }

  .active {
    margin-top: 2.5px;
    margin-left: 10.825em;
    font-size: 18px;
  }

  .mainContent {
    margin: 0;
    height: auto;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .main-1 {
    width: 100%;
    margin-left: 0;
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .main-2 {
    flex-direction: column;
  }

  .firstText {
    font-size: 20px;
    text-align: center;
  }

  .secondText {
    font-size: 24px;
    text-align: center;
  }

  h5 {
    padding-top: 9%;
    font-size: 17px;
    text-align: center;
  }

  .start {
    padding-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn_2 {
    width: 40%;
    height: 4em;
    cursor: pointer;
    position: relative;
    left: 27.5%;
    top: -43px;
    font-size: 11px;
  }

  .form {
    width: 95%;
    height: 42px;
    font-size: 10px;
    padding-left: 4%;
  }

  .mobile {
    margin-top: 0%;
    margin-right: 0%;
    width: 70%;
    margin-bottom: 15%;
  }

  .laptop {
    display: none;
  }

  /* ............... WHY WE ........... */

  .why_we {
    flex-direction: column;
    height: 400px;
  }

  .numbers {
    font-size: 25px;
  }

  .number_description {
    font-size: 12px;
  }

  .textWhy {
    font-size: 20px;
    -webkit-text-stroke-width: 0.05px;
  }

  .whyCaro {
    margin: 8% 2%;
  }

  .swiper-slide-active {
    -webkit-text-decoration: underline 2px solid #111111;
            text-decoration: underline 2px solid #111111;
  }

  .slider {
    width: 82%;
  }


  /* ............ OUR WORK FLOW .............. */


  .flow {
    flex-direction: column;
    margin-bottom: 5%;
  }

  .flow div {
    flex-direction: column;
  }

  .circle1, .circle2, .circle3, .circle4{
    width: 40px;
    height: 40px;
    font-size: 10px;
  }

  .ourWork {
    height: auto;
    margin: 0 7% 0 7%;
    border-radius: 20px 20px 0 0;
  }

  .textOur {
    margin-top: 5%;
    font-size: 24px;
  }

  .ourWork h4 {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 3%;
    text-align: center;
  }



  .vector1 {
    width: 43%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 15%;
    height: 10px;
  }

  .vector2 {
    width: 43%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    height: 10px;
    margin-top: 15%;
  }

  .vector3 {
    width: 43%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 15%;
    height: 10px;
  }

  .circle {
    margin-top: 7%;
  }

  .circle h3 {
    font-size: 14px;
    line-height: 14px;
    font-weight: bolder;
  }

  .circle h4 {
    font-size: 12px;
    line-height: 12px;
  }


  /* ............. EXPORT......... */

  .imagerow {
    flex: 100% 1;
  }

  .imageAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111111;
    height: 92vh;
    flex-direction: column;
  }

  .imgAni {
    position: relative;
    top: 39em;
    right: 7em;
    opacity: 1;
  }

  .export {
    margin-top: -70%;
  }

  .export h1 {
    font-size: 95px;
    font-weight: 400;
    line-height: 550px;
  }

  .export h3 {
    font-size: 22px;
    line-height: 95px;
    margin-top: -86%;
    margin-left: 5%;
  }

  .noImg1 {
    width: 70%;
    top: -40em;
    left: 3em;
  }

  .noImg2 {
    width: 70%;
    top: -41em;
    left: 5em;
  }

  .noImg3 {
    width: 70%;
    top: -41em;
    left: 9em;

  }

  .noImg4 {
    width: 70%;
    top: -42em;
    left: 12em;

  }

  /* .............our team carousel............ */

  .founder {
    margin-top: 7%;
    margin-bottom: 10%;
  }

  .founderimage {
    width: 80%;
  }

  .founderheading {
    margin-top: 6%;
    margin-bottom: 10%;
  }

  .founderheading h1 {
    font-size: 32px;
  }

  /* ..............FOOTER............... */

  .circles {
    height: 340px;
    margin-top: -30%;
    margin-left: 0%;
    width: 130%;
  }

  footer {
    margin: 0 5%;
    height: 300px;
  }

  .logo-f img {
    margin-left: -8%;
    margin-top: 20%;
    width: 30%;
  }

  .SC {
    margin-left: 0%;
    font-size: 30px;
    margin-top: 8%;
  }

  .circles p {
    width: 70%;
    left: 0%;
    top: 0%;

    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 20px;
    color: #464646;
  }

  .col-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .col-1 div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col-1 div p {
    margin-left: 20px;
    font-size: 12px;
    margin-top: -20px;
  }

  footer {
    height: auto;
    border-radius: 14px 14px 0px 0px;
    margin-top: 5%;
  }

  footer div {
    display: flex;
    flex-direction: column;
  }

  .col-1 div p {
    margin-top: -40px;
    margin-left: 10px;
    font-size: 10px;
  }


  footer .col-2 .col {
    margin-top: 15%;
    margin-right: 0px;
  }

  footer .col-2 .col h4 {
    font-size: 20px;
    line-height: 6px;
    color: #ffffff;
    padding-bottom: 8%;
  }

  footer .col-2 .col a {
    line-height: 10px;
    font-size: 16px;
  }

  .col-2 div div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .col-2 div div p {
    color: #F2F4F6;
    font-family: work sans;
    padding: 2% 2% 2% 0%;
    font-weight: 400;
    font-size: 10px;
  }

}
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f2f4f6;
    overflow-x: hidden;
}

.contactPage {
    font-family: "Poppins", Sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100dvw;
    padding-top: 9em;
    overflow: hidden;
}

.topper h1 {
    color: white;
    background-color: #111111;
    width: 49em;
    height: 5em;
    text-align: center;
    padding-top: 3.3em;
    margin-top: -1.7em;
}

.contactUs {
    width: 100%;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5em;
    padding-bottom: 3em;
}


.headingContact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: auto;
}

.headingContact h1 {
    font-size: 56px;
    margin: 2% 0% 0 7%;
    /* margin: 2%; */
}

.headingContact img {
    position: relative;
    left: 45%;
    cursor: pointer;
}

.textareas {
    text-align: left;
    margin: auto;
    width: 650px;
    margin-top: -55%;
    margin-left: 33em;
}

.map {
    margin-top: 4em;
}

.textareas label {
    display: flex;
}

.textareas input {
    width: 90%;
    height: 40px;
    border-radius: 5px;
    border-style: solid;
    margin: 1%;
    font-size: 16px;
}

.clearfix {
    display: flex;
}

.clearfix input {
    width: 78.5%;
}

.wtext input {
    width: 18.3rem;
    margin-right: 0.2em;
}

.wtext2 label {
    margin-left: 2em;
}

.wtext2 input {
    margin-left: 2em;
    width: 84%;
}


.phonefield {
    display: flex;
}

span.wrap-services {
    margin-right: 30em;
    margin-bottom: 0em;
}

.wrap-services span {
    display: flex;
    margin-bottom: 0.4em;
}

.wrap-services input {
    height: 1em;
    margin-top: 1px;
    margin-right: -3.4em;
    margin-left: -3.5em;
}

.form-last textarea {
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid #5d5d66;
    border-radius: 7px;
    color: #000;
    display: block;
    resize: none;
    margin-left: 35em;
    width: 55%;
}

.pricingPlans{
    width: 100%;
    margin-top: 3%;
    margin-left: 0.5%;
}

.pricingPlans select {
    
    width: 96.5%;
    height: 45px;
    border-radius: 5px;
    border-width: 2px;
}


.midForm {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-label label {
    margin-bottom: 1.3em;

}

.wrap-services .text {
    margin-right: -15em;
}

.service-label {
    display: flex;
    flex-direction: column;
}

.countrycodecontainer input {
    margin-top: 0.14em;
    width: 2.3em;
    margin-left: -2.7em;
}

.phone-text input {
    width: 15.5em;
}

.submit {
    border-radius: 5px;
    border-width: 1px;
    border: none;
    font-size: 20px;
    font-weight: 900;
    background-color: #00618e;
    color: #fff;
    height: 2.3em;
    width: 57%;
    margin-top: 1em;
    margin-left: 23.5em;
}

.submit:hover {
    cursor: pointer;
    background-color: #004463;
}

.socialMedia {
    margin-top: 1.4%;
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.socialMedia img {
    width: 20%;
    /* margin: 2%; */
}

.details {
    display: flex;
    margin: 1%;
    width: 40%;
    /* justify-content: space-between; */
}

/* .right{
    margin-left: 20em;
    margin-top: -5em;
} */

#contact-page-form {
    display: flex;
}

.details img {
    width: 12%;
    height: auto;
}

.details h1 {
    -webkit-text-fill-color: rgb(88, 83, 83);
    margin-left: 2%;
    font-size: 28px;
}

.submitButton {
    margin: 2%;
}

.submitButton2 {
    background-color: #00618E;
    color: #F2F4F6;
    border-radius: 0.625em;
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    font-family: Work Sans;
    font-size: 15px;
}


.contact-info {
    text-align: center;
    margin-top: 3em;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boxes {
    display: flex;
    /* margin-left: 7%; */
    margin-top: 3em;
    margin-bottom: 5em;
    justify-content: center;
    align-items: center;
}

.support-mail {
    border: 1px solid #00618E;
    margin: 1%;
    height: 14.5em;
    padding: 1em;
    padding-top: 2em;
    border-radius: 20px;
}

.support-mail:hover {
    cursor: pointer;
}

.support-mail img {
    width: 130px;
    margin-bottom: 1em;
}

.premium-icon-box-content {
    font-size: 0.75em;
    line-height: 1.3em;
    margin-top: 1em;
}

.social-links {
    text-align: center;
    font-size: 22px;
    margin-bottom: 3em;
    padding-top: 1em;
}

.social-links a img {
    width: 40px;
    margin: 1.3em;
    margin-top: 3em;
}

.social-links a img:hover {
    cursor: pointer;
    width: 41px;
}


@media (min-width:1160px) and (max-width: 1368px) {

    .contactUs {
        margin-top: 4em;
        margin-left: 5em;
        margin-right: 7em;
    }

    .headingContact img {
        left: 25%;
        width: 35px;
        height: 35px;
    }

    .headingContact h1 {
        font-size: 33px;
    }

    .textareas {
        margin-left: 42em;
        margin-top: -50%;
    }

    .textareas input {
        width: 75%;
    }

    .map {
        margin-left: 6em;
    }

    .textarea-placeholder textarea {
        width: 88%;
    }

    .socialMedia {
        justify-content: left;
        margin-left: 1%;
    }

    .details {
        margin-left: 1%;
    }

    .details img {
        width: 10%;
    }

    .socialMedia img {
        width: 14%;
    }

    .clearfix input {
        width: 66.5%;
    }

    .wtext input {
        width: 15.6rem;
        margin-right: 2.6em;
    }

    .wtext2 input {
        width: 69%;
    }

    .countrycodecontainer input {
        margin-top: 0.14em;
        width: 2.3em;
        margin-left: -2.7em;
    }

    .phone-text input {
        width: 12.6em;
    }

    .form-last textarea {
        width: 46.2%;
        margin-left: 44.5em;
    }

    .submit {
        width: 48.2%;
        margin-left: 29.5em;
    }

    .pricingPlans select {
    
        width: 88.5%;
        height: 45px;
        border-radius: 5px;
        border-width: 2px;
    }

    .support-mail {
        margin: 1%;
        height: 17.5em;
        padding: 1em;
        padding-top: 2em;
        border-radius: 20px;
        /* margin-left: -1.5em; */
    }

}

@media (min-width:768px) and (max-width: 1160px) {

    .right-contact {
        margin-left: -31em;
        margin-top: 46em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    .textarea-placeholder textarea {
        width: 88%;
    }


    .clearfix input {
        width: 79%;
    }

    .clearfix .right label {
        margin-left: -2.9em;
    }

    .wtext input {
        width: 18.4rem;
        margin-right: 2.6em;
    }

    .wtext2 input {
        width: 93.5%;
        margin-left: -0.48em;
    }

    .clearfix .wtext2 label {
        margin-left: -0.9em;
    }

    .countrycodecontainer input {
        margin-top: 0.14em;
        width: 2.3em;
        margin-left: -2.5em;
    }

    .phone-text input {
        width: 15.2em;
        margin-left: 0.5em;
    }

    .form-last textarea {
        width: 54.2%;
    }

    .submit {
        width: 56%;
    }

    .support-mail {
        margin: 1%;
        height: 15em;
        padding: 1em;
        padding-top: 2em;
        border-radius: 20px;
        /* margin-left: -1.5em; */
    }


    .contact-info {
        align-items: center;
        /* margin-left: -2em; */
    }

    .premium-icon-box-content {
        font-size: 14px;
        margin-left: -1.3em;
    }

}

@media (min-width:640px) and (max-width: 768px) {


    .right-contact {
        margin-left: -31em;
        margin-top: 46em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    .textarea-placeholder textarea {
        width: 88%;
    }


    .clearfix input {
        width: 79%;
    }

    .clearfix .right label {
        margin-left: -2.9em;
    }

    .wtext input {
        width: 18.4rem;
        margin-right: 2.6em;
    }

    .wtext2 input {
        width: 93.5%;
        margin-left: -0.48em;
    }

    .clearfix .wtext2 label {
        margin-left: -0.9em;
    }

    .countrycodecontainer input {
        margin-top: 0.14em;
        width: 2.3em;
        margin-left: -2.5em;
    }

    .phone-text input {
        width: 15.2em;
        margin-left: 0.5em;
    }

    .form-last textarea {
        width: 54.2%;
    }

    .submit {
        width: 56%;
    }

    .premium-icon-box-content {
        font-size: 12px;
    }

    .support-mail img {
        margin-bottom: 1em;
        width: 4em;
    }

    .contact-info {
        align-items: center;
    }

    .support-mail {
        margin: 1%;
        height: 13.5em;
        width: 5.2em;
        padding-top: 2em;
        margin-left: 0%;
        margin-right: 1%;
    }

    .boxes {
        display: flex;
        margin-left: 2%;
        margin-top: 3em;
        margin-bottom: 5em;
        margin-right: 0%;
    }


}

@media (min-width:361px) and (max-width: 640px) {
    .topper {
        font-size: 10px;
        margin-top: -10em;
    }

    .contactUs div .headingContact h1 {
        font-size: 30px;
        margin-left: -0.7em;
    }


    .right-contact {
        margin-left: -28em;
        margin-top: 46em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    .textareas {
        margin-left: 31em;
    }

    #contact-page-form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    #contact-page-form .left {
        margin-left: -1em;
    }

    #contact-page-form .right {
        margin-top: 0em;
        margin-left: -1em;
    }

    .pricingPlans{
        margin-left: 21.5%;
    }


    .pricingPlans select {
    
        width: 66%;
        height: 45px;
        border-radius: 5px;
        border-width: 2px;
    }

    .clearfix {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .clearfix .left {
        margin-left: 3.1em;
        width: 30.2em;
    }

    .clearfix .right {
        margin-left: -1em;
        margin-top: 0em;
    }

    .clearfix .right label {
        margin-left: 0em;
    }

    .phone {
        margin-left: -3.3em !important;
    }

    .clearfix .phone-text {
        width: 17.7em;
        margin-left: -1%;
    }

    .countrycodecontainer input {

        margin-left: -3.3em;
    }

    .clearfix .wtext {
        width: 20.9em;
    }

    .clearfix .right .wtext2 input {
        margin-left: -1.3em;
        width: 20.9em;
    }

    .service-label {
        margin-left: 11em;
    }

    .service-label .wrap-services input {
        margin-left: -1em;
    }

    .service-label .wrap-services .text {
        margin-left: 3em;
    }

    .form-last textarea {
        width: 23em;
        margin-left: 43em;
    }

    .submit {
        width: 16.4em;
        margin-left: 28.5em;
    }



    .contact-info {
        align-items: center;
    }

    .boxes {
        display: block;
        margin-left: 3%;
    }


    .support-mail {
        margin-bottom: 1em;
        height: 16em;
        margin-left: 0%;
        width: 83.2%;
        margin-right: 1%;
    }

    .clearfix .right label {
        margin-left: -1.7em;
    }


}

@media (min-width:280px) and (max-width: 360px) {

    .topper {
        font-size: 6px;
        margin-top: -10em;
    }

    .contactUs div .headingContact h1 {
        font-size: 25px;
        margin-left: -0.7em;
    }

    .right-contact {
        margin-left: -24em;
        margin-top: 41em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    #contact-page-form .left {
        margin-left: 11em;
        width: 17em;
    }

    #contact-page-form .right {
        margin-top: 5em;
        margin-left: -17em;
        width: 17em;
    }

    .clearfix .left {
        margin-left: 11em;
        width: 19.5em;
    }

    .clearfix .right {
        margin-left: -16.8em;
        margin-top: 5em;
    }

    .pricingPlans{
        margin-left: 24%;
    }


    .pricingPlans select {
    
        width: 45%;
        height: 45px;
        border-radius: 5px;
        border-width: 2px;
    }

    .countrycodecontainer input {
        margin-left: -2em;
    }

    .clearfix .right label {
        margin-left: -2.9em;
    }

    .clearfix .phone-text {
        width: 10.7em;
    }

    .clearfix .wtext {
        width: 13.6em;
    }

    .clearfix .right .wtext2 input {
        margin-left: -2.2em;
        width: 13.7em;
    }

    .service-label {
        margin-left: 9em;
    }

    .service-label .wrap-services input {
        margin-left: -1em;
    }

    .service-label .wrap-services .text {
        margin-left: 2.6em;
    }

    .form-last textarea {
        width: 15.5em;
        margin-left: 45.8em;
    }

    .submit {
        width: 11.5em;
        margin-left: 30.5em;
    }

    .boxes {
        display: block;
        margin-left: 1em;
    }

    .contact-info {
        align-items: center;
        margin-left: 0em;
    }

    .support-mail {
        margin-bottom: 1em;
        height: 16em;
        width: 77%;
    }

    .textareas {
        margin-top: -48%;
    }
}
* {
  margin: 0;
  padding: 0;
}

.pricingBack {
  background-color: #111111;
  height: 100vh;

}

.pricing {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 0;
  width: 100%;
  font-size: 95%;
}

.note {
  background-color: #111111;
  color: white;
  padding: 5%;
  font-size: 20px
}

.Planes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 18%;
}

.divide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}


.scale {
  background-color: #FFFFFF;
  border-radius: 17px;
  margin: 1%;
  position: relative;
  display: inline-block;

  transition-duration: 0.5s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  padding: 2em 1em 2em 1em;
}

.scale:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  background-color: #00618E;
  color: #FFFFFF;
}


.scale:hover .hover {
  background-color: #00618e;
  color: #FFFFFF;
}


.scale:hover .ChooseButton2 {
  background-color: #FFFFFF;
  color: #00618E;
  /* padding-bottom: 0.5em; */
}

.scale:hover .ChooseButton2 a{
  color: #00618E;
}


.Planes div div h1 {
  margin-left: 4%;
  font-size: 28px;
  line-height: 150%;
}

.Planes div div h2 {
  margin-left: 4%;
  font-size: 13px;
  line-height: 180%;
  font-weight: 400;
}

.features div {
  display: flex;
  flex-direction: row;
}

.Planes div input {
  margin-right: 1%;
  border-radius: 50%;
}

.Planes div div p {
  margin-left: 3%;
  font-size: 15px;
  font-weight: 600;
  line-height: 150%;
}


.ChooseButton2 {
  background-color: #00618E;
  color: #F2F4F6;
  border-radius: 50px;
  width: 100%;
  height: 35px;
  border: none;
  cursor: pointer;
  font-family: Work Sans;
  font-size: 15px;
  margin-top: 0.7em;
}

.ChooseButton2:hover {
  background-color: #FFFFFF;
  color: #00618E;
}

.ChooseButton2 a{
  text-decoration: none;
  color:#F2F4F6;
}

@media (min-width: 280px) and (max-width: 360px) {
  .pricingBack {
    height: 230vh;
  }

  /* .box-3 {
    height: 330px;
    width: 256px;
    padding: 1em 1em 1em 1em;
    font-size: 1em;
  } */

  .divide{
    flex-direction: column;
  }

  .Planes {
    flex-direction: column;
    top: 70px;
  }

  .scale:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }

  .pricing h1 {
    font-size: 80px;
  }

  .ChooseButton2 {
    font-size: 13px;
    height: 30px;
  }

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 18px;
    line-height: 84%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 8px;
    line-height: 120%;
  }

  .Planes div div p {
    font-size: 12px;
  }

  .animatPrice1 {
    margin-left: 7%;
    margin-top: 25%;
  }

  .animatPrice2 {
    margin-left: 40%;
    margin-top: 15%;
  }

  .animatPrice3 {
    display: block;
    margin-left: 7%;
    margin-top: 15%;
  }

  .animatPrice4 {
    display: block;
    margin-left: 40%;
    margin-top: 15%;
  }

  .animatPrice5 {
    display: block;
    margin-left: 7%;
    margin-top: 15%;
  }

  .pricing {
    width: 30%;
  }

  .scale {
    margin: 2% 2%;
  }

  #foot {
    margin-top: 50px;
  }

  .note {
    padding: 10%;
    font-size: 16px
  }
}


@media (min-width: 361px) and (max-width: 600px) {
  .pricingBack {
    height: 230vh;
  }


  /* .box-3 {
    height: 330px;
    width: 256px;
    padding: 1em 1em 1em 1em;
    font-size: 1em;
  } */

  .divide{
    flex-direction: column;
  }

  .Planes {
    flex-direction: column;
    top: 70px;
  }

  .scale:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }


  .ChooseButton2 {
    font-size: 13px;
    height: 30px;
  }

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 18px;
    line-height: 84%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 8px;
    line-height: 120%;
  }

  .Planes div div p {
    font-size: 12px;
  }


  .pricing {
    width: 100%;
  }

  .scale {
    margin: 2% 2%;
  }

  #foot {
    margin-top: 50px;
  }

  .note {
    padding: 5%;
    font-size: 16px
  }
}


@media (min-width: 600px) and (max-width: 768px) {
  .pricingBack {
    height: auto;
  }


  /* .box-3 {
  height: 330px;
  width: 256px;
  padding: 1em 1em 1em 1em;
  font-size: 1em;
} */

.Planes {
  flex-direction: column;
  padding-top: 15%;
}

  .scale:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }


  .ChooseButton2 {
    font-size: 13px;
    height: 30px;
  }

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 18px;
    line-height: 84%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 8px;
    line-height: 120%;
  }

  .Planes div div p {
    font-size: 12px;
  }


  .pricing {
    width: 100%;
  }

  .scale {
    margin: 2% 2%;
  }

  #foot {
    margin-top: 50px;
  }

  .note {
    padding: 5%;
    font-size: 16px
  }
}

@media (min-width:768px) and (max-width: 934px) {
  .pricingBack {
    height: auto;
  }


  /* .box-3 {
    height: 330px;
    width: 256px;
    padding: 1em 1em 1em 1em;
    font-size: 1em;
  } */

  .Planes {
    flex-direction: column;
    padding-top: 15%;
  }

  .scale:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }


  .ChooseButton2 {
    font-size: 13px;
    height: 30px;
  }

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 18px;
    line-height: 84%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 8px;
    line-height: 120%;
  }

  .Planes div div p {
    font-size: 12px;
  }


  .pricing {
    width: 100%;
  }

  .scale {
    margin: 2% 2%;
  }

  #foot {
    margin-top: 50px;
  }

  .note {
    padding: 5%;
    font-size: 16px
  }
}

@media (min-width:934px) and (max-width: 1064px) {
  /* .Planes div div h1 {
    margin-left: 2%;
    font-size: 22px;
    line-height: 140%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 12px;
    line-height: 160%;
  }

  .box-3 {
    height: 441px;
  }

  .pricing {
    width: 100%;
  }

  .Planes {
    top: 22%;
  }

  .Planes div {
    margin: 2% 2%;
  } */
  .pricingBack {
    height: auto;
  }

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 22px;
    line-height: 140%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 12px;
    line-height: 160%;
  }

  /* .box-3 {
      height: 441px;
    } */

  .pricing {
    width: 100%;
  }

  .Planes {
    flex-direction: column;
    padding-top: 15%;
  }

  .scale {
    margin: 2% 2%;
  }

  #foot {
    margin-top: 50px;
  }

  .note {
    padding: 5%;
    font-size: 16px
  }
}


/* .scale {
    height: 24em;
    padding: 0.5em 0em 0em 0.2em;
  }

  .box-3 {
    height: 410px;
    width: 330px;
    padding: 0em 1em 1em 1em;
    font-size: 1.4em;
  }

  .pricing h1 {
    font-size: 200px;
  }

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 22px;
    line-height: 140%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 12px;
    line-height: 160%;
  }


  .scale:hover .ChooseButton2 {
    background-color: #FFFFFF;
    color: #00618E;
    padding-bottom: 1.5em;
  }

  .animatPrice1 {
    margin-left: 7%;
  }

  .animatPrice2 {
    margin-left: 40%;
    margin-top: 6%;
  }
} */

/* @media (min-width:768px) and (max-width: 1064px) {
  .pricing {
    width: 100%;
  }

  .ChooseButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ChooseButton2 {
    width: 10em;
    margin-left: 0em;
  }

  .scale {
    height: 28em;
    padding: 0.5em 0em 0em 0.2em;
  }


  .Planes div div h1 {
    margin-left: 2%;
    font-size: 17px;
    line-height: 140%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 10px;
    line-height: 160%;
  }


  .scale:hover .ChooseButton2 {
    background-color: #FFFFFF;
    color: #00618E;
    padding-bottom: 1.5em;
  }

  .box-3 .hover {
    padding-top: 0.1em;
  }

  .Planes {
    top: 22%;
  }

  .box-3 {
    height: 400px;
    width: 280px;
    padding: 0.4em 1em 1em 1em;
    font-size: 1.4em;
  }


  .Planes div {
    margin: 2% 2%;
  }

}

@media (min-width:768px) and (max-width: 934px) {

  .pricingBack {
    height: 270vh;
  }

  .scale {
    height: 24em;
    padding: 0.5em 0em 0em 0.2em;
  }

  .box-3 {
    height: 370px;
  }

  .Planes {
    top: 8%;
    flex-direction: column;
  }

  .pricing {
    margin-left: -5em;
  }

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 20px;
    line-height: 84%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 10px;
    line-height: 120%;
  }

  .Planes div div p {
    font-size: 14px;
  }

} */

@media (min-width:1065px) and (max-width: 1368px) {

  .Planes div div h1 {
    margin-left: 2%;
    font-size: 22px;
    line-height: 140%;
  }

  .Planes div div h2 {
    margin-left: 2%;
    font-size: 12px;
    line-height: 160%;
  }

  /* .box-3 {
    height: 441px;
  } */

  .pricing {
    width: 100%;
  }

  .Planes {
    top: 22%;
  }

  .scale {
    margin: 2% 2%;
  }

  #foot {
    margin-top: 50px;
  }

  .note {
    padding: 5%;
    font-size: 16px
  }

}
.container{
    margin: 80px 30px 30px 80px;
}
.casestudy{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    text-align: center;
    height: 100vh;
}
