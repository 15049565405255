.slider-div{
    overflow-x: hidden;
}

.slider-div .swiper-slide {
    background-position: center;
    background-size: cover;
    color: #AFC9DC;
    -webkit-text-fill-color: transparent;
}

.slider-div .swiper-slide-active {
    font-size: 54px;
    color: #00618E;
    text-decoration: underline 8px solid #111111;
    -webkit-text-fill-color: #00618E;
}


.slider-div .slider {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 36%;
}


.profile-sect {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.profile-sec {
    display: flex;
    gap: 16px;
}

.slider-image {
    width: 150px; /* Set a fixed width */
    height: 150px; /* Set a fixed height */
    object-fit: contain; /* Ensures the image is scaled to fit without stretching */
    display: block; /* Ensures it behaves like a block element */
    margin: auto; /* Centers the image horizontally */
  }
  


@media (min-width: 1065px) and (max-width: 1368px) {
    .swiper-slide-active {
        font-size: 40px;
    }

    .slider-div .slider {
        width: 38%;
    }

}

@media (min-width: 768px) and (max-width: 1064px) {
    .swiper-slide-active {
        font-size: 30px;
    }

    .slider-div .slider {
        width: 53%;
    }

    .slider {
        display: flex;
        justify-content: center;
        align-content: center;

    }
}

@media(min-width: 640px) and (max-width:768px) {
    .swiper-slide-active {
        text-decoration: underline 5px solid #111111;
    }

    .slider-div .slider {
        width: 60%;
    }
}

@media (min-width: 360px) and (max-width:640px) {
    .swiper-slide-active {
        text-decoration: underline 2px solid #111111;
    }

    .slider {
        width: 68%;
    }

    .slider-div .slider {
        width: 60%;
    }

    .slider-image {
        width: 70px; /* Set a fixed width */
        height: 90px; /* Set a fixed height */
      }


}

@media (min-width: 240px) and (max-width:359px) {
    .swiper-slide-active {
        text-decoration: underline 2px solid #111111;
    }

    .slider-div .slider {
        width: 78%;
    }

    .slider-image {
        width: 70px; /* Set a fixed width */
        height: 90px; /* Set a fixed height */
      }
}