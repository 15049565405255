.animated-divs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.animated-div {
  display: flex;
  justify-content: center;
  align-items: center;

}


.field1 {
  background-color: #111111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.field {
  background-color: #111111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100vh;
  margin-left: -2em;
}



.frames {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.frames img {
  width: 40em;
}

.frame {
  margin: 5%;
  margin-top: 10%;
}

.frameText {
  margin-top: 6%;
  color: #F2F4F6;
  text-decoration: underline;
  text-decoration-color: #00618E;
  font-size: 3.8em;
  font-family: "Saudagar";
  font-weight: 300;
}

.textdes {
  margin-top: 1%;
  color: #F2F4F6;
  margin-top: 2%;
  font-size: 18px;
  line-height: 1.4;
  width: 30em;
  margin-right: 13em;
}

.TEXT {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: -10em;
  margin-left: 3em;
}


.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: -19.8em;
  ;
  margin-top: -2em;
}

.btnOur {
  background-color: #00618E;
  color: #F2F4F6;
  border-radius: 10px;
  border: none;
  width: 150px;
  height: 35px;
  box-shadow: 0px 10px 70px #00618E;
  margin-top: 30%;
  margin-left: -1.7em;
}

.btnOur {
  cursor: pointer;
}

.btnUs {
  background-color: #111111;
  color: #F2F4F6;
  border-radius: 10px;
  border-color: #F2F4F6;
  width: 150px;
  height: 35px;
  margin-left: 7%;
  margin-top: 30%;
}

.btnUs:hover {
  cursor: pointer;
}


@media (min-width: 1065px) and (max-width: 1365px){
  .textdes{
    font-size: 16px;
  }
  .frames img {
    width: 30em;
}
}


@media (min-width:768px) and (max-width: 1064px) {
  .field {
    height: auto;
    margin-left: 0;
    flex-direction: column;
  }
  .TEXT {
    margin-left: 0em;
    font-size: 18px;
    margin-right: 0;
  }

  .textdes{
    margin-right: 0;
  }

  .frames img {
    width: 35em;
  }

  .buttons {
    margin-left: 0em;
    width: 17em;
  }
}

@media (min-width: 640px) and (max-width: 768px) {

  .field {
    height: auto;
    margin-left: 0;
    flex-direction: column;
  }
  .TEXT {
    margin-left: 0em;
    font-size: 18px;
    margin-right: 0;
  }

  .frameText {
    font-size: 2.8em;
  }

  .textdes{
    margin-right: 0;
  }

  .frames img {
    width: 35em;
  }

  .buttons {
    margin-left: 0em;
    width: 17em;
  }
}



@media (min-width: 360px) and (max-width: 640px) {
  .field {
    flex-direction: column;
    height: auto;
    width: 10em;
  }

  .TEXT {
    margin-left: 4em;
    font-size: 11px;
    margin-right: 0;
  }

  .frames img {
    width: 65%;
  }

  .frameText {
    width: 7em;
  }

  .frames img {
    width: 22em;
  }

  .frame {
    margin-top: 17%;
    margin-left: 17%;
  }

  .textdes {
    width: 15.5em;
    margin-right: 0em;
    font-size: 18px;
  }


  .buttons {
    margin-left: -5em;
    width: 17em;
  }

}


@media (min-width: 280px) and (max-width: 360px) {

  .field {
    flex-direction: column;
    height: auto;
    width: 10em;
    margin-left: 0.1%;
  }

  .TEXT {
    font-size: 11px;
    margin-top: 7em;
    margin-right: 1.5em;
  }

  .frames img {
    width: 140%;
    margin-top: 2em;
  }

  .frameText {
    width: 9em;
    font-size: 27px;
  }

  .textdes {
    width: 14.5em;
    margin-right: -3em;
  }



  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: column;
    margin-top: 0.4em;
    margin-left: -5em;
    width: 19em;
  }



  .btnOur {
    height: 35px;
    margin-top: 10%;
    margin-left: 5%;
  }


  .btnUs { 
    height: 35px;
    margin-top: 1.2em;
  }

}