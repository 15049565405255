.projects{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    margin: 0% 10% 0% 10%;
}

.framesp{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projectheading{
    margin-top: 8%;
    margin-bottom: 8%;
}

.projectheading h1{
    font-style: work sans;
    font-weight: 600;
    font-size: 36px;
}

.projectimage{
    width: 90%;
    margin-bottom: 5%;
    display: flex; 
    flex-wrap: wrap;
}

.imagerow{
    flex: 25%;
}

.tilehover img{
    width: 100%;
    /* display: block; */
}

.tilehover{
  position: relative;
  margin: 2%;
}


.bottom{
  position: absolute; 
  bottom: 0; 
  
  background: rgba(167, 164, 164, 0.5); /* Black see-through */
  color: #e9e4e4; 
  transition: .5s ease;
  opacity:0;
  width: 100%;
  font-size: 10px;
  /* padding: 20px; */
  text-align: center;
  bottom: -40px;
  overflow: hidden;
}
.tilehover:hover .bottom{
  opacity: 1;
  bottom: 2px;
}

@media (min-width:480px) and (max-width: 1064px){
  .imagerow{
    flex: 50%;
}
}

@media (min-width: 360px) and (max-width:480px){
  .imagerow{
    flex: 100%;
}
}
