body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f2f4f6;
    overflow-x: hidden;
}

.contactPage {
    font-family: "Poppins", Sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100dvw;
    padding-top: 9em;
    overflow: hidden;
}

.topper h1 {
    color: white;
    background-color: #111111;
    width: 49em;
    height: 5em;
    text-align: center;
    padding-top: 3.3em;
    margin-top: -1.7em;
}

.contactUs {
    width: 100%;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5em;
    padding-bottom: 3em;
}


.headingContact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: auto;
}

.headingContact h1 {
    font-size: 56px;
    margin: 2% 0% 0 7%;
    /* margin: 2%; */
}

.headingContact img {
    position: relative;
    left: 45%;
    cursor: pointer;
}

.textareas {
    text-align: left;
    margin: auto;
    width: 650px;
    margin-top: -55%;
    margin-left: 33em;
}

.map {
    margin-top: 4em;
}

.textareas label {
    display: flex;
}

.textareas input {
    width: 90%;
    height: 40px;
    border-radius: 5px;
    border-style: solid;
    margin: 1%;
    font-size: 16px;
}

.clearfix {
    display: flex;
}

.clearfix input {
    width: 78.5%;
}

.wtext input {
    width: 18.3rem;
    margin-right: 0.2em;
}

.wtext2 label {
    margin-left: 2em;
}

.wtext2 input {
    margin-left: 2em;
    width: 84%;
}


.phonefield {
    display: flex;
}

span.wrap-services {
    margin-right: 30em;
    margin-bottom: 0em;
}

.wrap-services span {
    display: flex;
    margin-bottom: 0.4em;
}

.wrap-services input {
    height: 1em;
    margin-top: 1px;
    margin-right: -3.4em;
    margin-left: -3.5em;
}

.form-last textarea {
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid #5d5d66;
    border-radius: 7px;
    color: #000;
    display: block;
    resize: none;
    margin-left: 35em;
    width: 55%;
}

.pricingPlans{
    width: 100%;
    margin-top: 3%;
    margin-left: 0.5%;
}

.pricingPlans select {
    
    width: 96.5%;
    height: 45px;
    border-radius: 5px;
    border-width: 2px;
}


.midForm {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-label label {
    margin-bottom: 1.3em;

}

.wrap-services .text {
    margin-right: -15em;
}

.service-label {
    display: flex;
    flex-direction: column;
}

.countrycodecontainer input {
    margin-top: 0.14em;
    width: 2.3em;
    margin-left: -2.7em;
}

.phone-text input {
    width: 15.5em;
}

.submit {
    border-radius: 5px;
    border-width: 1px;
    border: none;
    font-size: 20px;
    font-weight: 900;
    background-color: #00618e;
    color: #fff;
    height: 2.3em;
    width: 57%;
    margin-top: 1em;
    margin-left: 23.5em;
}

.submit:hover {
    cursor: pointer;
    background-color: #004463;
}

.socialMedia {
    margin-top: 1.4%;
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.socialMedia img {
    width: 20%;
    /* margin: 2%; */
}

.details {
    display: flex;
    margin: 1%;
    width: 40%;
    /* justify-content: space-between; */
}

/* .right{
    margin-left: 20em;
    margin-top: -5em;
} */

#contact-page-form {
    display: flex;
}

.details img {
    width: 12%;
    height: auto;
}

.details h1 {
    -webkit-text-fill-color: rgb(88, 83, 83);
    margin-left: 2%;
    font-size: 28px;
}

.submitButton {
    margin: 2%;
}

.submitButton2 {
    background-color: #00618E;
    color: #F2F4F6;
    border-radius: 0.625em;
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    font-family: Work Sans;
    font-size: 15px;
}


.contact-info {
    text-align: center;
    margin-top: 3em;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.boxes {
    display: flex;
    /* margin-left: 7%; */
    margin-top: 3em;
    margin-bottom: 5em;
    justify-content: center;
    align-items: center;
}

.support-mail {
    border: 1px solid #00618E;
    margin: 1%;
    height: 14.5em;
    padding: 1em;
    padding-top: 2em;
    border-radius: 20px;
}

.support-mail:hover {
    cursor: pointer;
}

.support-mail img {
    width: 130px;
    margin-bottom: 1em;
}

.premium-icon-box-content {
    font-size: 0.75em;
    line-height: 1.3em;
    margin-top: 1em;
}

.social-links {
    text-align: center;
    font-size: 22px;
    margin-bottom: 3em;
    padding-top: 1em;
}

.social-links a img {
    width: 40px;
    margin: 1.3em;
    margin-top: 3em;
}

.social-links a img:hover {
    cursor: pointer;
    width: 41px;
}


@media (min-width:1160px) and (max-width: 1368px) {

    .contactUs {
        margin-top: 4em;
        margin-left: 5em;
        margin-right: 7em;
    }

    .headingContact img {
        left: 25%;
        width: 35px;
        height: 35px;
    }

    .headingContact h1 {
        font-size: 33px;
    }

    .textareas {
        margin-left: 42em;
        margin-top: -50%;
    }

    .textareas input {
        width: 75%;
    }

    .map {
        margin-left: 6em;
    }

    .textarea-placeholder textarea {
        width: 88%;
    }

    .socialMedia {
        justify-content: left;
        margin-left: 1%;
    }

    .details {
        margin-left: 1%;
    }

    .details img {
        width: 10%;
    }

    .socialMedia img {
        width: 14%;
    }

    .clearfix input {
        width: 66.5%;
    }

    .wtext input {
        width: 15.6rem;
        margin-right: 2.6em;
    }

    .wtext2 input {
        width: 69%;
    }

    .countrycodecontainer input {
        margin-top: 0.14em;
        width: 2.3em;
        margin-left: -2.7em;
    }

    .phone-text input {
        width: 12.6em;
    }

    .form-last textarea {
        width: 46.2%;
        margin-left: 44.5em;
    }

    .submit {
        width: 48.2%;
        margin-left: 29.5em;
    }

    .pricingPlans select {
    
        width: 88.5%;
        height: 45px;
        border-radius: 5px;
        border-width: 2px;
    }

    .support-mail {
        margin: 1%;
        height: 17.5em;
        padding: 1em;
        padding-top: 2em;
        border-radius: 20px;
        /* margin-left: -1.5em; */
    }

}

@media (min-width:768px) and (max-width: 1160px) {

    .right-contact {
        margin-left: -31em;
        margin-top: 46em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    .textarea-placeholder textarea {
        width: 88%;
    }


    .clearfix input {
        width: 79%;
    }

    .clearfix .right label {
        margin-left: -2.9em;
    }

    .wtext input {
        width: 18.4rem;
        margin-right: 2.6em;
    }

    .wtext2 input {
        width: 93.5%;
        margin-left: -0.48em;
    }

    .clearfix .wtext2 label {
        margin-left: -0.9em;
    }

    .countrycodecontainer input {
        margin-top: 0.14em;
        width: 2.3em;
        margin-left: -2.5em;
    }

    .phone-text input {
        width: 15.2em;
        margin-left: 0.5em;
    }

    .form-last textarea {
        width: 54.2%;
    }

    .submit {
        width: 56%;
    }

    .support-mail {
        margin: 1%;
        height: 15em;
        padding: 1em;
        padding-top: 2em;
        border-radius: 20px;
        /* margin-left: -1.5em; */
    }


    .contact-info {
        align-items: center;
        /* margin-left: -2em; */
    }

    .premium-icon-box-content {
        font-size: 14px;
        margin-left: -1.3em;
    }

}

@media (min-width:640px) and (max-width: 768px) {


    .right-contact {
        margin-left: -31em;
        margin-top: 46em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    .textarea-placeholder textarea {
        width: 88%;
    }


    .clearfix input {
        width: 79%;
    }

    .clearfix .right label {
        margin-left: -2.9em;
    }

    .wtext input {
        width: 18.4rem;
        margin-right: 2.6em;
    }

    .wtext2 input {
        width: 93.5%;
        margin-left: -0.48em;
    }

    .clearfix .wtext2 label {
        margin-left: -0.9em;
    }

    .countrycodecontainer input {
        margin-top: 0.14em;
        width: 2.3em;
        margin-left: -2.5em;
    }

    .phone-text input {
        width: 15.2em;
        margin-left: 0.5em;
    }

    .form-last textarea {
        width: 54.2%;
    }

    .submit {
        width: 56%;
    }

    .premium-icon-box-content {
        font-size: 12px;
    }

    .support-mail img {
        margin-bottom: 1em;
        width: 4em;
    }

    .contact-info {
        align-items: center;
    }

    .support-mail {
        margin: 1%;
        height: 13.5em;
        width: 5.2em;
        padding-top: 2em;
        margin-left: 0%;
        margin-right: 1%;
    }

    .boxes {
        display: flex;
        margin-left: 2%;
        margin-top: 3em;
        margin-bottom: 5em;
        margin-right: 0%;
    }


}

@media (min-width:361px) and (max-width: 640px) {
    .topper {
        font-size: 10px;
        margin-top: -10em;
    }

    .contactUs div .headingContact h1 {
        font-size: 30px;
        margin-left: -0.7em;
    }


    .right-contact {
        margin-left: -28em;
        margin-top: 46em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    .textareas {
        margin-left: 31em;
    }

    #contact-page-form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    #contact-page-form .left {
        margin-left: -1em;
    }

    #contact-page-form .right {
        margin-top: 0em;
        margin-left: -1em;
    }

    .pricingPlans{
        margin-left: 21.5%;
    }


    .pricingPlans select {
    
        width: 66%;
        height: 45px;
        border-radius: 5px;
        border-width: 2px;
    }

    .clearfix {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .clearfix .left {
        margin-left: 3.1em;
        width: 30.2em;
    }

    .clearfix .right {
        margin-left: -1em;
        margin-top: 0em;
    }

    .clearfix .right label {
        margin-left: 0em;
    }

    .phone {
        margin-left: -3.3em !important;
    }

    .clearfix .phone-text {
        width: 17.7em;
        margin-left: -1%;
    }

    .countrycodecontainer input {

        margin-left: -3.3em;
    }

    .clearfix .wtext {
        width: 20.9em;
    }

    .clearfix .right .wtext2 input {
        margin-left: -1.3em;
        width: 20.9em;
    }

    .service-label {
        margin-left: 11em;
    }

    .service-label .wrap-services input {
        margin-left: -1em;
    }

    .service-label .wrap-services .text {
        margin-left: 3em;
    }

    .form-last textarea {
        width: 23em;
        margin-left: 43em;
    }

    .submit {
        width: 16.4em;
        margin-left: 28.5em;
    }



    .contact-info {
        align-items: center;
    }

    .boxes {
        display: block;
        margin-left: 3%;
    }


    .support-mail {
        margin-bottom: 1em;
        height: 16em;
        margin-left: 0%;
        width: 83.2%;
        margin-right: 1%;
    }

    .clearfix .right label {
        margin-left: -1.7em;
    }


}

@media (min-width:280px) and (max-width: 360px) {

    .topper {
        font-size: 6px;
        margin-top: -10em;
    }

    .contactUs div .headingContact h1 {
        font-size: 25px;
        margin-left: -0.7em;
    }

    .right-contact {
        margin-left: -24em;
        margin-top: 41em;
    }

    .map {
        margin-left: 9.5em;
        margin-top: 1em;
    }

    #contact-page-form .left {
        margin-left: 11em;
        width: 17em;
    }

    #contact-page-form .right {
        margin-top: 5em;
        margin-left: -17em;
        width: 17em;
    }

    .clearfix .left {
        margin-left: 11em;
        width: 19.5em;
    }

    .clearfix .right {
        margin-left: -16.8em;
        margin-top: 5em;
    }

    .pricingPlans{
        margin-left: 24%;
    }


    .pricingPlans select {
    
        width: 45%;
        height: 45px;
        border-radius: 5px;
        border-width: 2px;
    }

    .countrycodecontainer input {
        margin-left: -2em;
    }

    .clearfix .right label {
        margin-left: -2.9em;
    }

    .clearfix .phone-text {
        width: 10.7em;
    }

    .clearfix .wtext {
        width: 13.6em;
    }

    .clearfix .right .wtext2 input {
        margin-left: -2.2em;
        width: 13.7em;
    }

    .service-label {
        margin-left: 9em;
    }

    .service-label .wrap-services input {
        margin-left: -1em;
    }

    .service-label .wrap-services .text {
        margin-left: 2.6em;
    }

    .form-last textarea {
        width: 15.5em;
        margin-left: 45.8em;
    }

    .submit {
        width: 11.5em;
        margin-left: 30.5em;
    }

    .boxes {
        display: block;
        margin-left: 1em;
    }

    .contact-info {
        align-items: center;
        margin-left: 0em;
    }

    .support-mail {
        margin-bottom: 1em;
        height: 16em;
        width: 77%;
    }

    .textareas {
        margin-top: -48%;
    }
}